<template>
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header pt-2 pb-2">
          <h2 class="card-title">Ofertas Adicionales</h2>
        </div>
        <div class="card-body p-0">
          <table
            id="users"
            class="table table-bordered table-striped table-hover table-sm"
            style="font-size: 0.85em"
          >
            <thead>
              <tr>
                <th>
                  Solicitud
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    style="width: 100px"
                    v-model="filtros.solicitud_id"
                    @input="getIndex()"
                  />
                </th>
                <th>
                  Fecha solicitud
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="filtros.fechaSolicitud"
                    @change="getIndex()"
                  />
                </th>
                <th>
                  Sitio
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="filtros.sitio"
                    placeholder="Sitio"
                    label="nombre"
                    :options="listasForms.sitios"
                    :filterable="false"
                    @search="buscarSitios"
                    @input="selectSitio()"
                  ></v-select>
                </th>

                <th>Nominación</th>
                <th>Cantidad</th>
                <!-- <th>Justificación</th>-->
                <th>
                  Fecha vencimiento
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="filtros.fechaVencimiento"
                    @input="getIndex()"
                  />
                </th>

                <th>
                  Tipo Ruta
                  <select
                    class="form-control form-control-sm"
                    v-model="filtros.tipo_ruta"
                    @change="getIndex()"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tr in listasForms.tipo_rutas"
                      :key="tr.id"
                      :value="tr.numeracion"
                    >
                      {{ tr.descripcion }}
                    </option>
                  </select>
                </th>
                <th>
                  Tipo Vehiculo
                  <select
                    class="form-control form-control-sm"
                    v-model="filtros.tipo_vehiculo_id"
                    @change="getIndex()"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tr in listasForms.tipo_vehiculo"
                      :key="tr.id"
                      :value="tr.id"
                    >
                      {{ tr.nombre }}
                    </option>
                  </select>
                </th>
                <th>
                  Tipo Producto
                  <select
                    class="form-control form-control-sm"
                    v-model="filtros.tipo_producto_id"
                    @change="getIndex()"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="producto in listasForms.tipo_producto"
                      :key="producto.id"
                      :value="producto.id"
                    >
                      {{ producto.nombre }}
                    </option>
                  </select>
                </th>
                <th>
                  Producto Liquido
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="filtros.producto_liquido"
                    placeholder="Producto"
                    label="nombre"
                    :options="listasForms.productos_liquidos"
                    @search="buscarProductoLiquido"
                    @input="selectProductoLiquido()"
                  ></v-select>
                </th>

                <th style="width: 50px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="of in ofertas.data" :key="of.id">
                <td>
                  {{ of.solicitud_id }}
                </td>
                <td v-if="of.solicitud.fecha !== null">
                  {{ of.solicitud.fecha }}
                </td>
                <td v-else></td>
                <td>{{ of.solicitud.sitio.nombre }}</td>

                <td>{{ of.solicitud.nominacion.nombre }}</td>
                <td class="text-center">
                  {{ of.cantidad }}
                </td>
                <td>
                  <div>{{ of.fecha_vencimiento }}</div>
                </td>
                <td>{{ of.nTipoRuta }}</td>
                <td v-if="of.tipo_vehiculo !== null">
                  {{ of.tipo_vehiculo.nombre }}
                </td>
                <td v-else></td>
                <td v-if="of.tipo_producto_id !== null">
                  {{ of.producto ? of.producto.nombre : "" }}
                </td>
                <td v-else></td>
                <td v-if="of.producto_liquido !== null">
                  {{ of.producto_liquido.nombre }}
                </td>
                <td v-else></td>
                <td>
                  <div class="btn-group float-right">
                    <button
                      type="button"
                      class="btn btn-sm bg-navy"
                      data-toggle="modal"
                      data-target="#modal-form"
                      @click="getPostulaciones(of.id)"
                    >
                      <i class="fas fa-eye"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="float-left" v-if="ofertas.total">
            <p>
              Mostrando del <b>{{ ofertas.from }}</b> al
              <b>{{ ofertas.to }}</b> de un total:
              <b>{{ ofertas.total }}</b> Registros
            </p>
          </div>
          <div class="float-left" v-else>
            <p>
              <span class="badge badge-danger">
                No hay registros para mostrar
              </span>
            </p>
          </div>
          <pagination
            :data="ofertas"
            @pagination-change-page="getIndex"
            :limit="5"
            align="right"
          ></pagination>
        </div>
      </div>

      <div
        class="modal fade"
        id="modal-form"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">Postulados</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div
                class="row m-3 border"
                v-for="postulacion in postulaciones"
                :key="postulacion.id"
              >
                <div class="col-md-1">
                  <h2
                    class="mt-3 ml-1"
                    :class="
                      postulacion.estado == 1
                        ? 'text-warning'
                        : postulacion.estado == 2 || postulacion.estado == 3
                        ? 'text-success'
                        : 'text-danger'
                    "
                  >
                    <i
                      :class="
                        postulacion.estado == 1
                          ? 'fas fa-exclamation-triangle'
                          : postulacion.estado == 2 || postulacion.estado == 3
                          ? 'fas fa-check-circle'
                          : 'fas fa-window-close'
                      "
                    ></i>
                  </h2>
                </div>
                <div class="col-md-3">
                  <label>Empresa</label>
                  <p>{{ postulacion.empresa.razon_social }}</p>
                </div>
                <div class="col-md-2">
                  <label>Vehículo</label>
                  <p>{{ postulacion.vehiculo.placa }}</p>
                </div>
                <div class="col-md-3">
                  <label v-if="postulacion.remolque != null">Remolque</label>
                  <p>
                    {{
                      postulacion.remolque != null
                        ? postulacion.remolque.placa
                        : ""
                    }}
                  </p>
                </div>
                <div class="col-md-3">
                  <label>Conductor</label>
                  <p>
                    {{
                      "(" +
                        postulacion.conductor.numero_documento +
                        ") - " +
                        postulacion.conductor.nombres +
                        " " +
                        postulacion.conductor.apellidos
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";

export default {
  name: "OfertaAdicionalesFront",
  components: {
    vSelect,
    pagination,
  },
  data() {
    return {
      falgValidando: false,
      ofertas: {},
      oferta: {},
      postulaciones: [],
      filtros: {},
      listasForms: {
        tipo_rutas: [],
        tipo_vehiculo: [],
        sitios: [],
        tipo_producto: [],
        productos_liquidos: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.$parent.cargando = true;
      axios
        .get("/api/hidrocarburos/ofertaAdicional?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.ofertas = response.data;
          this.$parent.cargando = false;
        });
    },

    getPostulaciones(oferta_id) {
      let params = {
        oferta_adicional_id: oferta_id,
      };

      axios
        .get("/api/hidrocarburos/ofertaAdicional/indexPostulacion", {
          params,
        })
        .then((response) => {
          this.postulaciones = response.data;
        });
    },

    buscarSitios(search) {
      let me = this;
      me.listasForms.sitios = [];
      if (search != "") {
        let url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then((response) => {
            me.listasForms.sitios = response.data;
          })
          .catch((e) => {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarProductoLiquido(search) {
      let me = this;
      me.listasForms.productos_liquidos = [];
      if (search != "") {
        let url =
          "api/hidrocarburos/Productos/lista?producto_liquido=" + search;
        axios
          .get(url)
          .then((response) => {
            me.listasForms.productos_liquidos = response.data;
          })
          .catch((e) => {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitio() {
      this.filtros.sitio_id = null;
      if (this.filtros.sitio) {
        this.filtros.sitio_id = this.filtros.sitio.id;
      }
      this.getIndex();
    },
    selectProductoLiquido() {
      this.filtros.product_liquido_id = null;
      if (this.filtros.producto_liquido) {
        this.filtros.product_liquido_id = this.filtros.producto_liquido.id;
      }
      this.getIndex();
    },

    buscarEmpresas(search) {
      let me = this;
      me.listasForms.empresas = [];
      if (search != "") {
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.empresas = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.filtros.empresa) {
        this.filtros.empresa_id = this.filtros.empresa.id;
      }
      this.getIndex();
    },

    getTipoRutas() {
      this.listasForms.tipo_rutas = [];
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },

    /*     getTipoVehiculo() {
      axios.get("/api/admin/tiposVehiculos/lista").then((response) => {
        this.listasForms.tipo_vehiculo = response.data;
      });
    }, */

    getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    getTipoProducto() {
      axios.get("/api/admin/productos/lista").then((response) => {
        this.listasForms.tipo_producto = response.data;
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getTipoRutas();
    this.getTipoVehiculo();
    this.getTipoProducto();
  },
};
</script>
